import React from "react";
import { Container } from "@mui/material";


const Termsandcondition=()=>
{
    return (
        <>
            
            <section className="section">
            <Container maxWidth='lg'>
            <h1 className='head_title'>Terms and conditons</h1><br></br>
                <span className='policy_title'>These Terms of Use include the <span style={{fontWeight:700}}>Parashar Creative Studio</span> Privacy Policy which is incorporated into these Terms of Use by reference. By using, you are indicating your acceptance to be bound by these Terms and Conditions, including the <span style={{fontWeight:700}}>Parashar Creative Studio</span> Privacy Policy. If ‘You’ do not accept the Terms of Use stated herein, do not use the Web Site and its services. <span style={{fontWeight:700}}>Parashar Creative Studio</span> may revise these Terms of Use at any time by updating this posting. You should visit this page periodically to review the Terms of Use because they are binding on you. The terms "You" and "User" as used herein refer to all individuals and/or entities accessing this website for any reason. Copying or downloading or recreating or sharing passwords or sublicensing or sharing in any manner which is not in accordance with these terms, is a misuse of the platform or service and <span style={{fontWeight:700}}>Parashar Creative Studio</span> reserves its rights to act in such manner as to protect its loss of revenue or reputation or claim damages including stopping your service or access and reporting to relevant authorities. In the event you are found to be copying or misusing or transmitting or crawling any data or photographs or graphics or any information available on for any purpose other than that being a bonafide Purpose, we reserve the right to take such action that we deem fit including stopping access and claiming damages.</span><br></br><br></br>
                 <span className='policy_title'>You give us permission to use the information about actions that you have taken on in connection with ads, offers and other content (whether sponsored or not) that we display across our services, without any compensation to you. We use data and information about you to make relevant suggestions and recommendation to you and others.</span><br></br><br></br>
                 
                 <h1 className='head_title'>Consent</h1><br></br>
                 <span className='policy_title'>By using our site, registering an account, you consent to this Terms of Service.</span><br></br><br></br>
                 <span className='policy_title'>If any dispute arises between a user/users and <span style={{fontWeight:700}}>Parashar Creative Studio</span> arising out of use of  or thereafter, in connection with the validity, interpretation, implementation or alleged breach of any provision of these Terms and Conditions, the dispute shall be referred to a sole arbitrator who shall be an independent and neutral third party identified by the Company. Decision of the arbitrator shall be final and binding on both the parties to the dispute. The place of arbitration shall be at Kannur, Kerala (India).
                </span><br></br><br></br>
                <span className='policy_title'>The Arbitration & Conciliation Act, 1996 as amended, shall govern the arbitration proceedings.</span><br></br><br></br>
                <h1 className='head_title'>Cookies</h1><br></br>
                <span className='policy_title'><span style={{fontWeight:700}}>Parashar Creative Studio</span> uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to personalize the Content that you see on our website. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our website correctly or at all. We never place Personally Identifiable Information in Cookies. By using our site or registering an account, you consent to the fact that we use cookies.</span><br></br><br></br>
                <h1 className='head_title'>Disclaimer</h1><br></br>
                <span className='policy_title'><span style={{fontWeight:700}}>Parashar Creative Studio</span> is not responsible for any content, code or any other imprecision.</span><br></br><br></br>
                <span className='policy_title'><span style={{fontWeight:700}}>Parashar Creative Studio</span> does not provide warranties or guarantees.</span><br></br><br></br>
                <span  className='policy_title'>In no event shall <span style={{fontWeight:700}}>Parashar Creative Studio</span> be liable for any special, direct, indirect, consequential, or incidental damages or any damages whatsoever, whether in an action of contract, negligence or other tort, arising out of or in connection with the use of the Service or the contents of the Service. The Company reserves the right to make additions, deletions, or modifications to the contents on the Service at any time without prior notice.</span><br></br><br></br>
                </Container>
            </section>

        </>
    )
}

export default Termsandcondition;