import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Stories from "./Stories";
import {useLocation} from "react-router-dom"
import { Helmet } from 'react-helmet';
import { Container } from "@mui/material";


const About=()=>
{
  
    const useFullCanonicalUrl = () => {
        const location = useLocation();
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        return `${baseUrl}${location.pathname}${location.search}`;
      };
      
      const canonicalUrl = useFullCanonicalUrl();

    return (
        <>
          
          <Helmet>

          <link rel="canonical" href={canonicalUrl} />
          </Helmet>
           
            <div>
             <Header/>
             <section style={{backgroundColor:"rgba(233, 225, 255, 1)"}} className="section" >
            <Container maxWidth='lg'>
           <h1 className='hero_title' style={{margin:"0px"}} >About us</h1>
           </Container>
           </section>
              <Stories/>
              <Footer/>
            </div>


        </>
    )
}
export default About;