import React from "react";
import { Box, Grid, Button } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createTheme, useMediaQuery } from "@mui/material";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";


const Stories=()=>
{

    const themes=createTheme({
        breakpoints:{
         values:
         {
            xs:0,
            sm:768
         }
        }
     })
    
     const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))
 

     const navigate = useNavigate()

     const RedirectUrl= process.env.REACT_APP_SITE_URL;
     
    const customButton=
    {
  
        backgroundColor:'var(--primary)',
        color:'#ffffff',
        fontSize:'18px',
        padding:'8px 24px',
        fontWeight: 500,
        textTransform:'none',
        margin:isSmscreen?'10px 0px 20px':'20px 0px 30px',
        letterSpacing: '0.8px',  
            '&:hover': {
        backgroundColor:'var(--primary)',
            },
        }

   const started=()=>
   {
       window.location.href=`${RedirectUrl}/register`
       
   }

    return(
        <>

     
          <section className="section" >
        
            <Container maxWidth='lg'>
     
            <Box>
             <Grid container rowSpacing={2} columnSpacing={{xs:2,sm:isSmscreen?4:6,md:6}} display="flex" justifyContent="space-between" alignItems='center' >
           
          
         <Grid item xs={12} sm={isSmscreen?12:6} md={6}>
       
      <p>Experience the power of our innovative e-commerce SAAS solution designed to boost your online sales like never before! 

            With cutting-edge technology, our platform delivers a seamless experience for both you and your customers.Experience the power of our innovative e-commerce SAAS solution designed to boost your online sales like never before! 

            With cutting-edge technology, our platform delivers a seamless experience for both you and your customers.Experience the power of our innovative e-commerce SAAS solution designed to boost your online sales like never before! 

            With cutting-edge technology, our platform delivers a seamless experience for both you and your customers.Experience the power of our innovative e-commerce SAAS solution designed to boost your online sales like never before! 

            With cutting-edge technology, our platform delivers a seamless experience for both you and your customers.</p>


     
          </Grid>
    
              <Grid item xs={12} sm={isSmscreen?12:6} md={6} >
           
           
              <div   className="image_container" >
        
           </div>
       
         </Grid>
         
     </Grid>
        
              </Box>
  
              </Container>

           </section>
        </>
    )
}

export default Stories;