import React from "react";
import Grid from '@mui/material/Grid';
import { Box, createTheme, useMediaQuery } from "@mui/material";

import Container from '@mui/material/Container';
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";


const Header=()=>
{

    const themes=createTheme({
       breakpoints:{
        values:
        {
           xs:0,
           sm:768
        }
       }
    })
 
    const isSmscreen = useMediaQuery(themes.breakpoints.down('sm'))

    const RedirectUrl= process.env.REACT_APP_SITE_URL;

    const home=()=>
    {
      
      window.scrollTo({ top: 0, behavior:'instant' });
      window.location.replace('/');
    }

    const handlefreesignup=()=>
    {
        window.open(`${RedirectUrl}/register`, '_blank');
    }

    const handlelogin=()=>
   
    {
      window.open(`${RedirectUrl}/login`, '_blank');
    }

    const scrollToSection = () => {
      const section = document.getElementById('price');
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    };
   

    return (
        <>
          <div className="header">
          <Container maxWidth="lg">
           <Box sx={{width:"100%"}}>
           <Grid container  display="flex" justifyContent="space-between" alignItems="center" >
               <Grid item xs={5} sm={5} md={5}>
                    <div><LazyLoadImage className="logo-img" src='/lavendel.svg' alt="Lavendel"  loading="lazy" onClick={home}/></div>
                    </Grid>
                    <Grid item xs='none' sm={isSmscreen?{}:7} md={7} display="flex" justifyContent="flex-end">
                  <div className="navlink">
                       <NavLink to ="/" className="home black"> <div className="homehover">Home</div> </NavLink> 
                       <NavLink to=""  onClick={scrollToSection} className="home black"> <div className="homehover" >Price</div></NavLink> 
                       <NavLink to="/about-us" className="home black"><div className="homehover" >About Us</div></NavLink> 
                       <NavLink onClick={handlelogin} className="home black">  <div className="homehover" >Login</div></NavLink> 
                       <NavLink onClick={handlefreesignup} className="home signup">  <div>Try for free</div></NavLink> 

                               
                 </div>
                 </Grid>

                </Grid>
                </Box>  

       </Container>
          </div>
        

   
        </>
    )
}

export default Header;
