import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './global.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Terms from './Footer/Terms';
import Privacypolicy from './Footer/Privacy-policy';
import Returnpolicy from './Footer/Returnpolicy';
import Shipping from './Footer/Shipping';
import Refund from './Footer/Refund';
import About from './About/about';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 // <React.StrictMode>
 <BrowserRouter>
 <Routes>

   <Route path="/" element={<App/>}/>
   <Route path="/terms-and-conditions" element={<Terms/>}/>
   <Route path="/privacy-policy" element={<Privacypolicy/>}/>
   <Route path="/return-policy" element={<Returnpolicy/>}/>
   <Route path="/shipping" element={<Shipping/>}/>
   <Route path="/about-us" element={<About/>}/>
   <Route path="/refund-and-cancellation-policy" element={<Refund/>}/>

 </Routes>
 </BrowserRouter>
//  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
