import React from 'react'
import Header from '../Header/Header'
import Footer from './Footer'
import Shippingpolicy from './Shippingpolicy'
import { Container } from "@mui/material";

const Shipping=()=>
{
     return(
        <> 
            <div className='content'>
                <Header/>
                <section style={{backgroundColor:"rgba(233, 225, 255, 1)"}} className="section" >
                    <Container maxWidth='lg'>
                <h1 className='hero_title' style={{margin:"0px"}} >Shipping policy</h1>
                </Container>
                </section>
                <Shippingpolicy/>
                <Footer/>

            </div>
        </>
     )
}
export default Shipping;