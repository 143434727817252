import React from "react";
import { Container } from "@mui/material";
import { NavLink } from "react-router-dom";

const Shippingpolicy=()=>
{
    return (
        <>
            
           
            
            <section className="section">

           
            <Container maxWidth='lg'>
              
              <div>

              <h1 className='head_title'>Shipping Policy</h1><br></br>
            <span className='policy_title'>The orders for the user are shipped through registered domestic courier companies and/or speed post only. Order are shipped within <span style={{fontWeight:"700"}}>2 </span> to <span style={{fontWeight:"700"}}>5 days</span> from the date of the order and/or payment or as per the delivery date agreed at the time of order confirmation and delivering of the shipment, subject to courier company / post office norms. </span><br></br><br></br><br></br>
            <span className='policy_title'>Platform Owner shall not be liable for any delay in delivery by the courier company / postal authority. </span><br></br><br></br><br></br>
            <span className='policy_title'>Delivery of all orders will be made to the address provided by the buyer at the time of purchase. Delivery of our services will be confirmed on your email ID as specified at the time of registration. If there are any shipping cost(s) levied by the seller or the Platform Owner (as the case be), the same is not refundable. For any issues in utilizing our services you may contact our helpdesk on within <span style={{fontWeight:"700"}}>2 days</span>.</span><br></br><br></br>
              </div>

                </Container>

            </section>

        </>
    )
}

export default Shippingpolicy;