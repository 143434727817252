import React from 'react'
import Header from '../Header/Header'
import Footer from './Footer'
import Return from './Return'
import { Container } from "@mui/material";

const Returnpolicy=()=>
{
     return(
        <>
            
            <div className='content'>
                <Header/>
                <section style={{backgroundColor:"rgba(233, 225, 255, 1)"}} className="section" >
            <Container maxWidth='lg'>
           <h1 className='hero_title' style={{margin:"0px"}} >Return policy</h1>
           </Container>
           </section>
                <Return/>
                <Footer/>

            </div>
        </>
     )
}
export default Returnpolicy;