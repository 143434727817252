import React from 'react'
import Header from '../Header/Header'
import Footer from './Footer'
import Termsandcondition from './Termsandcondition'
import { Container } from "@mui/material";

const Terms=()=>
{
     return(
        <> 
            <div className='content'>
                <Header/>
                <section style={{backgroundColor:"rgba(233, 225, 255, 1)"}} className="section" >
                    <Container maxWidth='lg'>
                <h1 className='hero_title' style={{margin:"0px"}} >Terms and conditions</h1>
                </Container>
                </section>
                <Termsandcondition/>
                <Footer/>

            </div>
        </>
     )
}
export default Terms;