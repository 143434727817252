import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import Home from "./Home/Home";
import Premium from "./Home/Premium";



function App() {
  return (
  
    <>
    
            <div>
               <Header/>
               <Home/>
               <Premium/>
               <Footer/>
           
            </div>
          
    </>
  )
}

export default App;
