import React from "react";
import { Container } from "@mui/material";

const Privacy=()=>
{
    return (
        <>
         
         <section className="section">
            <Container maxWidth='lg'>
            <h1 className='head_title'>Privacy policy</h1><br></br>
            <span className='policy_title'>The <span style={{fontWeight:700}}>Parashar Creative Studio</span> web app is built as an ecommerce web application. This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</span> <br></br><br></br>
            <span className='policy_title'>If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. <br></br>

             The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at <span style={{fontWeight:700}}>Parashar Creative Studio</span> unless otherwise defined in this Privacy Policy.</span><br></br><br></br>

           <span className='policy_title'>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at <span style={{fontWeight:700}}>Parashar Creative Studio</span> unless otherwise defined in this Privacy Policy.</span><br></br><br></br>

            <h1 className='head_title'>Information Collection and Use</h1><br></br>
            <span className='policy_title'>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to Name, Mobile Number, Email & Photo. The information that we request will be retained by us and used as described in this privacy policy.</span><br></br><br></br>
            <span  className='policy_title'>The app does use third-party services that may collect information used to identify you. Link to the privacy policy of third-party service providers used by the app</span><br></br><br></br>
            <h1 className='head_title'>Log Data</h1><br></br>
            <span className='policy_title'>We want to inform you that whenever you use our Service, in case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.</span><br></br><br></br>
            <h1 className='head_title'>Security</h1><br></br>
            <span  className='policy_title'>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</span><br></br><br></br>
         </Container>
         </section>
        
        </>
    )
}

export default Privacy;